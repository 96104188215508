import React from 'react';

const Image = ({ src, onLoad, className, style }) => {
  const handleOnImageLoad = (e) => {
    const size = {
      width: e.target.width,
      height: e.target.height,
    };
    if (onLoad) {
      onLoad({ src, width: size.width, height: size.height });
    }
  };
  return (
    <img
      onLoad={handleOnImageLoad}
      src={src}
      className={className}
      style={style}
      alt={src}
    />
  );
};

export default Image;
