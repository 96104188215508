import React, {
  Fragment,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components';
import useDimensions from 'react-use-dimensions';
import Image from '../Image';

const Container = styled.div`
  width: 8.26in;
  margin: 0 auto;
  @media print {
    @page {
      size: A4;
    }
  }
`;

const Page = styled.div`
  font-family: proxima-nova, 'Noto Sans JP', sans-serif;
  font-weight: normal;
  display: flex;
  position: relative;
  flex-direction: column;
  page-break-after: always;
  box-sizing: border-box;
  justify-content: center;
  ${({ moreTopPad }) =>
    moreTopPad &&
    `
    padding-top: 30mm;
  `}
  background: white;
`;

const AvatarWrap = styled.div`
  height: ${({ size }) => size / 10 || 9}em;
  width: ${({ size }) => size / 10 || 9}em;
  flex-basis: ${({ size }) => size / 10 || 9}em;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const AvatarImage = styled.img`
  width: 100%;
  overflow: hidden;
  border-radius: 999px;
`;

const Avatar = ({ size, src }) => {
  return (
    <AvatarWrap size={size}>
      <AvatarImage src={src} />
    </AvatarWrap>
  );
};

const ImageContainer = styled.div`
  page-break-inside: avoid;
  box-sizing: border-box;
  padding-top: 1em;
  width: 100%;
`;

const SizedImage = styled(Image)`
  object-fit: contain;
  width: 100%;
`;

const getWorkImages = (work) => {
  switch (work.type) {
    case 'video':
      return work.videos.map((video) => video.picture_url);
    case 'image':
      return work.images.map((image) => image.urls.detail);
    case 'web_article':
      return work.web_articles.map((article) => article.image);
    case 'sound':
      return work.sounds.map((sound) => sound.picture_url);
    default:
      return [];
  }
};

const OuterCredsWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0.5em 0 1.5em;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 2em;
`;

const WorkCredsWrap = styled(({ creditCount, ...otherProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...otherProps} />
))`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: ${({ creditCount }) =>
    creditCount > 1 ? 'flex-start' : 'center'};
  position: relative;
  box-sizing: border-box;
  padding-top: 1em;
  page-break-after: always;
`;
const WorkCredsHeading = styled.div`
  width: 100%;
  text-align: left;
  font-size: 0.7em;
  font-weight: bold;
  margin-bottom: 1.1em;
  margin-left: 0.5em;
`;

const WorkCredWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 2em 0 0;
  margin-bottom: 1em;
  align-items: center;
`;
const WorkCredInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.7em;
`;
const WorkCredName = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 1em;
  letter-spacing: 0.03em;
`;
const WorkCredTitleList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: -3px;
`;

const WorkCredTitle = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  color: #333132;
  background-color: #dddddd;
  margin: 3px;
  padding: 4px 10px;
  width: fit-content;
  border-radius: 5px;
`;

const DEFAULT_AVATAR =
  'https://dyci7co52mbcc.cloudfront.net/static/images/default_avatar.jpg';

const WorkCreds = forwardRef(({ credits }, ref) => {
  return (
    <OuterCredsWrap ref={ref}>
      <WorkCredsHeading>Credits</WorkCredsHeading>
      <WorkCredsWrap creditCount={credits.length}>
        {credits.map((credit) => {
          return (
            <WorkCredWrap key={credit.user.id}>
              <Avatar
                src={credit.user.avatar?.thumb2x || DEFAULT_AVATAR}
                size={30}
              />
              <WorkCredInfoWrap>
                <WorkCredName>{credit.user.name}</WorkCredName>
                <WorkCredTitleList>
                  {credit.creative_roles.map((role) => {
                    return (
                      <WorkCredTitle key={role.id}>{role.name}</WorkCredTitle>
                    );
                  })}
                </WorkCredTitleList>
              </WorkCredInfoWrap>
            </WorkCredWrap>
          );
        })}
      </WorkCredsWrap>
    </OuterCredsWrap>
  );
});

const WorkInfoPlain = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const WorkTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 0 2rem 0;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
`;
const WorkTitleName = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  margin-top: 0.3em;
`;
const WorkDesc = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 0.9em;
  line-height: 1.5em;
  padding: 2.5em;
  white-space: pre-wrap;
  border-bottom: 1px solid #c4c4c4;
`;

const WorkTopPage = ({ work, userName, showDesc, showCreds }) => {
  const workImages = getWorkImages(work);
  const [ref, workInfoAndCreditsDimension] = useDimensions({
    liveMeasure: false,
  });
  const [imagesLoaded, toggleImagesLoaded] = useState(false);
  const [imageSizes, setImageSizes] = useState({});
  const imageSizeRefs = useRef({});
  useEffect(() => {
    setImageSizes(imageSizeRefs.current);
  }, [imagesLoaded]);
  const handleOnImageLoad = ({ width, height, src }) => {
    imageSizeRefs.current[src] = {
      width,
      height,
    };
    if (Object.keys(imageSizeRefs.current).length >= workImages.length) {
      toggleImagesLoaded(true);
    }
  };
  return (
    <Page>
      <div ref={ref}>
        <WorkInfoPlain>
          <WorkTitle>
            {work.title}
            <WorkTitleName>{userName}</WorkTitleName>
          </WorkTitle>
          {showDesc && (
            <WorkDesc>
              {(work.description || '').trim('') === ''
                ? ' '
                : work.description}
            </WorkDesc>
          )}
        </WorkInfoPlain>
        {showCreds && <WorkCreds credits={work.credits} />}
      </div>
      <div>
        {workImages.map((image, index) => {
          const remainingSpaceForImage =
            1000 - workInfoAndCreditsDimension.height;
          let height;
          if (index === 0) {
            height =
              remainingSpaceForImage > 300
                ? Math.min(remainingSpaceForImage, 1000)
                : Math.min(imageSizes[image]?.height, 450); // render image in next page
          } else {
            // max 2 images per page
            height = Math.min(imageSizes[image]?.height, 450);
          }
          return (
            <ImageContainer
              key={image}
              style={{
                height: `${height}px`,
                display: imagesLoaded ? 'block' : 'none',
              }}
            >
              <SizedImage
                src={image}
                onLoad={handleOnImageLoad}
                style={{ height: imageSizes[image]?.height ? '100%' : 'auto' }}
              />
            </ImageContainer>
          );
        })}
      </div>
    </Page>
  );
};

const ProfileWrap = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 20mm;
`;

const ProfileName = styled.div`
  font-size: 2.1rem;
  font-weight: bold;
  margin-top: 1.9rem;
  margin-bottom: 0.8em;
`;

const ProfileInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ProfileInfo = styled.div`
  font-size: 1em;
  letter-spacing: 0.02em;
  line-height: 1.3rem;
  color: #bdbdbd;
`;

const ProfileBio = styled.div`
  color: #333333;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: center;
  width: 24rem;
  padding-top: 1.3rem;
  margin-top: 1.3rem;
  border-top: 1px solid #c4c4c4;
`;

const ProfilePage = ({ profile }) => (
  <Page>
    <ProfileWrap>
      <Avatar src={profile.avatar.thumb2x || DEFAULT_AVATAR} />
      <ProfileName>{profile.name}</ProfileName>
      {profile.profession && (
        <ProfileInfoWrap>
          <ProfileInfo>{profile.profession}</ProfileInfo>
        </ProfileInfoWrap>
      )}
      {profile.location && (
        <ProfileInfoWrap>
          <ProfileInfo>{profile.location}</ProfileInfo>
        </ProfileInfoWrap>
      )}
      {profile.website && (
        <ProfileInfoWrap>
          <ProfileInfo>{profile.website}</ProfileInfo>
        </ProfileInfoWrap>
      )}
      <ProfileBio>{profile.bio}</ProfileBio>
    </ProfileWrap>
  </Page>
);

const worksPages = (works, profile, hideDesc, hideCreds) => {
  return works.map((work) => {
    const showDesc =
      !hideDesc &&
      work.description !== '' &&
      (work.description || '').trim() !== '';
    return (
      <Fragment key={work.id}>
        <WorkTopPage
          userName={profile.name}
          showDesc={showDesc}
          showCreds={!hideCreds}
          work={work}
        />
      </Fragment>
    );
  });
};

const PDFTemplate = ({
  profile,
  works,
  includeProfile,
  hideDesc,
  hideCreds,
}) => {
  return (
    <Container>
      {includeProfile && <ProfilePage profile={profile} />}
      {worksPages(works, profile, hideDesc, hideCreds)}
    </Container>
  );
};

export default PDFTemplate;
