/* eslint-disable camelcase */
import React from 'react';
import PDFTemplate from './components/PDF/PDFTemplate';

const App = ({ initialData }) => {
  const {
    profile,
    works,
    include_profile,
    hide_desc,
    hide_creds,
    preview,
  } = initialData;
  const Comp = (
    <PDFTemplate
      profile={profile}
      works={works}
      includeProfile={include_profile}
      hideDesc={hide_desc}
      hideCreds={hide_creds}
      preview={preview}
    />
  );
  return Comp;
};

export default App;
